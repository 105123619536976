import React from "react"
import Layout from "../components/layout"
import Map from "../components/map"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"
import News from "../components/news"
import StationLinks from "../components/stationLinks"

const IndexPage = () => (
  <Layout language="en">
    <SEO title="Taxi.is | Forsíða" />
    <div className="front-page ">
      <div className="row">
        <div className="fp-news col-md-5">
          <h2 className="title">Most recent News</h2>
          <News language="en" />
        </div>
        <div className="fp-taxi col-md-7">
          <h2 className="title">Stations</h2>
          <StationLinks />
        </div>
      </div>

      <div className="contact row">
        <div className="map col-md-5">
          <Map />
        </div>
        <div className="contact-form col-md-7">
          <ContactForm language="en" />
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
